<template>
  <div>
    <v-row>
      <v-col>
        <app-text-search-field @input="select()" v-model="searchParams.text" />
      </v-col>
      <v-col class="d-flex">
        <v-btn
          v-if="$acl.can('salesCreateEdit')"
          class="ml-auto text-capitalize mr-3"
          color="success"
          to="/vendas/venda/novo"
        >
          <v-icon class="mr-2">mdi-cart-plus</v-icon>
          Nova Venda
        </v-btn>
        <v-btn
          v-if="$acl.can('salesCreateEdit')"
          class="text-capitalize mr-3"
          color="secondary"
          to="/vendas/devolucao/novo"
        >
          <v-icon class="mr-2">mdi-cart-minus</v-icon>
          Nova Devolução
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="align-end">
      <v-col cols="3">
        <label class="text-muted" for="start_date">Data Inicial</label>
        <app-date-field
          flat
          solo
          background-color="grey lighten-4"
          v-model="searchParams.start_date"
          id="start_date"
        />
      </v-col>
      <v-col cols="3">
        <label class="text-muted" for="end_date">Data Final</label>
        <app-date-field
          flat
          solo
          background-color="grey lighten-4"
          v-model="searchParams.end_date"
          id="end_date"
        />
      </v-col>
      <v-col cols="1">
        <v-btn @click="select()" outlined color="primary">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-select
          v-model="searchParams.delivery_status"
          :items="deliveryStatusData"
          label="Entrega"
          @input="select()"
        />
      </v-col>
      <v-col>
        <OperationSelect
          v-model="searchParams.operation_id"
          @input="select()"
        />
      </v-col>
      <v-col>
        <SalesmanSearch
          :hidePosition="true"
          clearable
          @input="setSalesman($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PaymentMethodsSelect
          v-model="searchParams.payment_method"
          @input="select()"
          clearable
        />
      </v-col>
      <v-col> <ProductSearch @input="setProduct($event)" clearable /> </v-col>
      <v-col>
        <CustomerSearch
          @input="setCustomer($event)"
          clearable
          :showFinalCustomer="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs>
          <v-tab
            class="text-capitalize"
            v-for="(status, index) in statusData"
            :key="index"
            @click="search(status.value)"
          >
            {{ status.text }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="sales.data"
          :server-items-length="-1"
          :items-per-page="-1"
          hide-default-footer
          disable-sort
          @click:row="handleSaleView($event)"
        >
          <template v-slot:[`item.operation`]="{ item }">
            <div>
              <v-chip color="primary" x-small>
                {{ item.operation.description }} #{{ item.number }}
              </v-chip>
            </div>
            <small>{{ $format.dateTimeBr(item.created_at) }}</small>
          </template>
          <template v-slot:[`item.customer`]="{ item }">
            <span v-if="!item.customer">Consumidor Final</span>
            <span v-else>{{ item.customer.name }}</span>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ $format.dateTimeBr(item.created_at) }}
          </template>
          <template v-slot:[`item.net_total`]="{ item }">
            {{ $format.decimal(item.net_total) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <sale-status :value="item.status" />
          </template>
          <template v-slot:[`item.delivery_status`]="{ item }">
            <SaleDeliveryStatus
              v-if="item.delivery_type == 'delivery'"
              :deliveryStatus="item.delivery_status"
            />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu bottom left class="mr-1">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text icon color="primary">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-if="canPrint(item)" @click="printSale(item.id)">
                  <v-list-item-icon class="mr-2">
                    <app-icon>print</app-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Imprimir</v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="canStoreDelivery(item)"
                  :disabled="item.delivery_status == 'delivered'"
                  @click="scheduleDelivery(item.id)"
                >
                  <v-list-item-icon class="mr-2">
                    <app-icon>local_shipping</app-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Agendar Entregar</v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="
                    item.delivery_type == 'delivery' &&
                    $acl.can('deliveriesIndex')
                  "
                  @click="handleViewDeliveries(item.number)"
                >
                  <v-list-item-icon class="mr-2">
                    <app-icon>travel_explore</app-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Ver entregas</v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="showEditButton(item)"
                  @click="handleEdit(item)"
                >
                  <v-list-item-icon class="mr-2">
                    <app-icon>edit</app-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Editar</v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="canRevertOrder(item)"
                  @click="revertOrder(item)"
                >
                  <v-list-item-icon class="mr-2">
                    <app-icon>edit</app-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Reverter pedido</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <app-pagination @click="select($event)" :data="sales" />
      </v-col>
    </v-row>
    <SaleView ref="SaleView" @store="select()" />
  </div>
</template>

<script>
import SaleStatus from "@/components/sales/sections/SaleStatus.vue";
import SaleDeliveryStatus from "@/components/sales/sections/SaleDeliveryStatus.vue";
import SaleView from "@/components/sales/sections/SaleView.vue";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch.vue";
import CustomerSearch from "@/components/customer/ui/CustomerSearch.vue";
import OperationSelect from "@/components/operation/ui/OperationSelect.vue";
import ProductSearch from "@/components/product/ui/ProductSearch.vue";
import PaymentMethodsSelect from "@/components/payment-terms/ui/PaymentMethodsSelect.vue";

export default {
  components: {
    SaleStatus,
    SaleDeliveryStatus,
    SaleView,
    SalesmanSearch,
    CustomerSearch,
    OperationSelect,
    ProductSearch,
    PaymentMethodsSelect,
  },
  data() {
    return {
      sales: {},
      showFilter: false,
      searchParams: {
        text: null,
        status: null,
      },
      headers: [
        // { text: "Numero", value: "number", width: "10px" },
        { text: "Operação", value: "operation" },
        { text: "Cliente", value: "customer" },
        { text: "Vendedor", value: "salesman.name" },
        // { text: "Data", value: "created_at", align: "center" },
        { text: "Valor ", value: "net_total", align: "center" },
        { text: "Status ", value: "status", align: "center", width: "10%" },
        {
          text: "Entrega",
          value: "delivery_status",
          align: "center",
          width: "10%",
        },
        { text: "", value: "action", align: "center", width: "6px" },
      ],

      statusData: [
        {
          value: [],
          text: "Todos",
        },
        { value: ["draft"], text: "Rascunho" },
        { value: ["proposal"], text: "Orçamento" },
        { value: ["waiting_approval"], text: "Aguardando aprovação" },
        { value: ["order"], text: "Pedido" },
        { value: ["sale"], text: "Venda" },
        { value: ["canceled"], text: "Cancelado" },
      ],

      deliveryStatusData: [
        { value: "", text: "Todos" },
        { value: "not_delivered", text: "Sem Entrega" },
        { value: "partial", text: "Parcial" },
        { value: "delivered", text: "Entregue" },
      ],
    };
  },

  created() {
    this.select();
  },

  methods: {
    select(page = 1) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("sale/sale", this.searchParams)
        .then((response) => {
          this.sales = response.sales;
          this.$loading.finish();
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },
    search(status) {
      this.searchParams.status = status;
      this.select();
    },
    printSale(sale_id) {
      window.open(`/vendas/venda/imprimir/${sale_id}`);
    },
    scheduleDelivery(sale_id) {
      this.$router.push(`/entrega/novo/${sale_id}`);
    },

    handleEdit(sale) {
      if (sale.operation.id == 1) {
        this.$router.push(`/vendas/venda/editar/${sale.id}`);
      }

      if (sale.operation.id == 2) {
        this.$router.push(`/vendas/devolucao/editar/${sale.id}`);
      }
    },

    canStoreDelivery(sale) {
      if (sale.status == "sale" && sale.delivery_type !== "no_delivery") {
        return true && this.$acl.can("deliveryCreateEdit");
      }
      return false;
    },

    handleSaleView(sale) {
      this.$refs.SaleView.open(sale.id);
    },

    showEditButton(sale) {
      if (
        sale.status == "draft" ||
        sale.status == "proposal" ||
        sale.status == "waiting_approval"
      ) {
        return true && this.$acl.can("salesCreateEdit");
      }
      return false;
    },
    handleViewDeliveries(sale_number) {
      window.open(
        `/vendas/visualizar-entrega/?sale_number=${sale_number}`,
        "_blank"
      );
    },

    setSalesman(salesman) {
      this.searchParams.salesman_id = salesman ? salesman.id : null;
      this.select();
    },

    setCustomer(customer) {
      this.searchParams.customer_id = customer ? customer.id : null;
      this.select();
    },

    canRevertOrder(sale) {
      return sale.status == "order";
    },

    revertOrder(sale) {
      this.$loading.start();
      this.$http
        .update(`sale/revert-order`, sale.id)
        .then((response) => {
          this.$loading.finish();
          this.select();
        })
        .catch((error) => {
          this.$loading.finish();
          this.$message.error("Erro ao reverter pedido");
        });
    },

    canPrint(sale) {
      if (sale.status == "sale" || sale.status == "proposal") {
        return "true";
      }
    },
    setProduct(product) {
      this.searchParams.product_id = product ? product.id : null;
      this.select();
    },
  },
};
</script>

<style></style>
